<template>
    <div class="col-lg-6 justify-content-md-center">
        <div class="card border-0 shadow-sm h-100">
            <div class="card-body p-5 border-top border-primary border-2">
                <h2 class="lined"><span class="text-primary">Interesse</span> geweckt?</h2>
                <p class="text-muted mb-4">Melde Dich bei uns!</p>
                <div class="row gy-4">
                    <div class="col-lg-12">
                        <input class="form-control" v-model="name" type="text" name="name" placeholder="Name"
                            :disabled="success">
                    </div>
                    <div class="col-lg-6">
                        <input class="form-control" v-model="email" type="text" name="email" placeholder="E-Mail"
                            :disabled="success">
                    </div>
                    <div class="col-lg-6">
                        <input class="form-control" v-model="phone" type="tel" name="phone" placeholder="Telefon"
                            :disabled="success">
                    </div>
                    <label for="department" class="col-lg-6 col-form-label">Deine Leidenschaft</label>
                    <div class="col-lg-6">
                        <select class="form-select" v-model="department" name="department" id="department"
                            :disabled="success">
                            <option value="physio">Physiotherapie</option>
                            <option value="ergo">Ergotherapie</option>
                            <option value="logo">Logopädie </option>
                            <option value="admin">Praxisverwaltung</option>
                        </select>
                    </div>
                    <div class="col-lg-12">
                        <textarea class="form-control" v-model="message" name="message" rows="5"
                            placeholder="Deine Nachricht an uns" :disabled="success"></textarea>
                        <small><span class="text-muted">(Schreibe uns kurz, nach welcher Art Job du suchst
                                und was du bisher
                                so getan hast.)</span></small>
                    </div>
                    <div class="col-lg-12">
                        <button v-if="!success" :disabled="!enableSubmit" class="btn btn-primary w-100"
                            v-on:click="sendApplication"><i class="fas fa-envelope"></i> Senden</button>
                        <p class="text-success" v-if="success">Vielen Dank für Deine Nachricht! Wir melden
                            uns innerhalb der
                            nächsten zwei Tage bei dir.</p>
                        <p class="text-danger" v-if="error">Oops. Da ist etwas schief gelaufen. Probiers
                            gerne nochmal oder
                            ruf uns einfach an: <a v-bind:href="`tel:${$t('frame.phone')}`">{{
                                $t('frame.phone') }}</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Apply',
    data() {
        return {
            success: false,
            error: false,
            name: '',
            email: '',
            phone: '',
            department: 'ergo',
            message: ''
        };
    },
    computed: {
        enableSubmit() {
            return this.email !== '' && this.phone !== '';
        }
    },
    methods: {
        sendApplication() {
            this.success = false;
            this.error = false;
            this.axios.post('https://das-viavitum.de/api/bewerbung/', {
                name: this.name, email: this.email, phone: this.phone, department: this.department, message: this.message
            }).then((response) => {
                if (response.data.status === 1) {
                    this.success = true;
                } else {
                    this.error = true;
                }
            }).catch((error) => {
                console.error(error);
                this.error = true;
            });
        }
    }
};
</script>

<style lang="scss" scoped></style>
